@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


  body {
    margin: 0;
    background: #F8FBFF !important;
  }
  *{
      font-family: 'Montserrat', sans-serif !important;
  }

  /* ============ Button Loader Icon ================ */
  .css-18lrjg1-MuiCircularProgress-root{
    color: white !important;
    display: block !important;
    margin: auto !important;
  }

  /* ============ Signin Button ================ */
  .form-button{
    color: #fff;
    background-color: #553E97;
    box-shadow: none !important;
  }
  .form-button:hover{
    color: #fff !important;
    background-color: #663dbe !important;
    border-color: #663dbe !important;
  }

  /* ============ left nav styles ================ */
  .ant-menu-item{
    padding-left: 20px !important;
    margin-left: 22px !important;
    width: 192px !important;
    color: #202224;
    font-weight: 600;
    height: 47.85px !important;
    display: flex !important;
    align-items: center !important;
  }
  /* .ant-menu-item.ant-menu-item-selected{ */
li[selectedkeys="true"]{
    background-color: #553E97;
    color: #FFFFFF;
  }
  /* .ant-menu-item.ant-menu-item-selected > svg > path{ */
li[selectedkeys="true"] > svg > path{
    fill: white;
  }
li[selectedkeys="true"] > span{
    color: white;
  }
li[selectedkeys="true"]:hover{
  background-color: #553E97 !important;
  }
  .ant-menu-item > svg > path{
    fill: #202224;
  }

  .ant-menu{
    border-inline-end: none !important;
  }

  .ant-layout-sider-children{
    display: flex;
    flex-direction: column;
  }

  .ant-menu-title-content{
    margin-inline-start: 15px !important;
  }
  .ant-menu-item > svg{
    margin-bottom: -2px !important;
  }

  /* ============ error styles ================ */
.SelectHeightStyle .ant-form-item-control-input {
  margin-bottom: 11px !important;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #121212b2 !important;
}

.ant-form-item-explain-error {
  font-family: 'Montserrat', sans-serif !important;
  color: #FF0D0D !important;

}
.ant-input-outlined {
  border: 1px solid #D0D5DD;
  height: 50px;
  border-radius: 8px;
  box-shadow: none !important;
}
.ant-input-outlined:focus, .ant-input-outlined:hover {
  border-color: #D0D5DD !important;
}

.go4109123758 > div > div:nth-child(2) {
  font-family: Arial, sans-serif !important;
}


.ant-input-outlined:focus,
.ant-input-outlined:focus-within{
  border-color: #664A9D !important;
}
.ant-input-status-error.ant-input-status-error {
  border-color: #FF0D0D !important;
}

::placeholder {
  color: #66708580 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
}

.ant-checkbox-checked .ant-checkbox-inner{
  background-color:  #664A9D !important;
  border-color:  #664A9D !important;
}
.ant-checkbox-inner{
  border-color:  #d9d9d9 !important;
}


  /* ============ Button styles ================ */
  .header_menu:focus,
  .header_menu:visited,
  .header_menu:active{
    background-color: transparent !important;
    outline: none !important;
  }
  /* ============ Pagination Styles ================ */
  .ant-pagination-item.ant-pagination-item-active,
  .ant-pagination-item.ant-pagination-item-active:hover{
    font-weight: 600;
    background-color: #553E97 !important;
    border-color: #553E97;
  }
  .ant-pagination-item.ant-pagination-item-active > a,
  .ant-pagination-item.ant-pagination-item-active > a:hover{
    color: #fff;
  }
.ant-pagination-item-link-icon{
    color: #553E97 !important;
  }
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next{
    background-color: white !important;
  }
  .ant-pagination-item:hover{
    background-color: #F0F0F0 !important;
  }


  /* ============ Input Field styles ================ */
.search_input{
  width: 388px;
  height: 38px;
  left: 78px;
  border-radius: 20px;
  border: 0.6px solid #e2e2e2;
  background: #F5F6FA;
}
.search_input .ant-input-prefix{
margin: 0px 10px 0px 5px;
}
.search_input:hover,
.search_input:focus-within{
  width: 388px;
  height: 38px;
  left: 78px;
  border-radius: 20px;
  border: 0.6px solid #e2e2e2;
  background: #F5F6FA !important;
}